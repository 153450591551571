@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:ital,wght@1,700&display=swap');

/* Circlular */
@font-face {
  font-family: 'Circular';
  src: url('https://fonts.clutch.ca/circular-regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('https://fonts.clutch.ca/circular-medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Circular';
  src: url('https://fonts.clutch.ca/circular-bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}
