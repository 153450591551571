html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #272727;
}

strong {
  font-weight: 600;
}

a {
  color: #009ee0;
  font-weight: 600;
}
